.backdrop{
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
}

.backdrop__form{
    background-color: white;
    padding: 2rem;
    border-radius: 1rem;
    width: 30rem;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modal__closebtn{
    position: absolute;
    top: 10px;
    right: 10px;
    font-weight: bold;
    background: none;
    border: none;
    outline: none;
    font-size: 1rem;
    cursor: pointer;
}

.login__inputs{
    padding: 0.8rem;
    width: 100%;
    border: 1px solid #e6e6e6;
    border-radius: 8px;
    margin: 5px;
}

.login__heading{
    font-weight: bold;
    margin-bottom: 15px;
    margin-top: 20px;
}

.signup__toggle{
    width: 100%;
    background-color: #222222;
    color: yellow;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 10px;
    /* margin-top: 1rem; */
    border-radius: 3px;
    font-weight: bold;
}

.divider{
    position: relative;
    background-color: #e6e6e6;
    width: 100%;
    margin: 1rem;
    height: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.divider p{
    position: absolute;
    background-color: white;
    padding: 5px;
    margin: 0px;
}

.input__containers{
    display: flex;
    width: 100%;
}

.input__containers input{
    flex: 1;
    width: 100%;
}

.input__containers span{
    color: red;
}