.Footer{
    padding: 2rem;
    color: white;
    border-top: 1px solid #222222;
}

.footer__inner{
    display: flex;
    align-items:flex-start;
    justify-content: space-between;
    border-bottom: 0.5px solid #222222;
    padding: 1rem 0rem;
}

.footer__inner a{
    color: white;
}

.footer__inner h3{
    margin-bottom: 10px;
    color: yellow;
    text-decoration: underline;
}